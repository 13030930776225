const winners = [
  // horizonal
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  // vertical
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  // diagonal
  [0, 4, 8],
  [2, 4, 6]
];

export const isWinningTile = (tiles: string[], position: number) =>
  !!tiles[position] &&
  winners.some(
    winner =>
      winner.includes(position) &&
      winner.every(idx => tiles[idx] === tiles[position])
  );

export const getWinningTiles = (tiles: string[]) =>
  tiles
    .map((_, position) => position)
    .filter(position => isWinningTile(tiles, position));
