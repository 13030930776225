import React, { Component } from "react";
import { Game } from "./Game";
import styles from "./App.module.css";

export class App extends Component {
  state = {
    player1: "😻",
    player2: "😲"
  };

  render() {
    const { player1, player2 } = this.state;
    return (
      <div className={styles.app}>
        {!player1 || !player2 ? null : (
          <Game player1={player1} player2={player2} />
        )}
      </div>
    );
  }
}
