import React from "react";
import { classnames } from "./lib/classnames";
import styles from "./Tile.module.css";

interface Props {
  value: string;
  highlight?: boolean;
  disabled?: boolean;
  onClick(): void;
}

// https://mathiasbynens.be/notes/javascript-unicode#accounting-for-astral-symbols
const isSingleCharacter = (string: string) => Array.from(string).length === 1;

export const Tile = ({
  value,
  highlight = false,
  disabled = false,
  onClick
}: Props) => {
  const isValid = isSingleCharacter(value);
  const className = classnames({
    [styles.tile]: true,
    [styles.highlight]: highlight
  });

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled || isValid}
      className={className}
    >
      <svg viewBox="0 0 11 13">
        <text x="0" y="11">
          {isValid ? value : ""}
        </text>
      </svg>
    </button>
  );
};
