import React, { Component } from "react";
import { Board } from "./Board";
import { Tile } from "./Tile";
import { getWinningTiles } from "./lib/winning";
import styles from "./Game.module.css";

interface Props {
  player1: string;
  player2: string;
}

interface State {
  tiles: string[];
}

const initialState = {
  tiles: Array(9).fill("")
};

export class Game extends Component<Props, State> {
  state = initialState;

  onTileClick = (tile: number, player: string) => {
    this.setState(({ tiles }: { tiles: string[] }) => {
      const newTiles = [...tiles];
      newTiles[tile] = player;
      return { tiles: newTiles };
    });
  };

  determineCurrentPlayer() {
    const { player1, player2 } = this.props;
    const { tiles } = this.state;

    return tiles.filter(Boolean).length % 2 === 0 ? player1 : player2;
  }

  resetGame = () => {
    this.setState(initialState);
  };

  render() {
    const { player1, player2 } = this.props;
    const { tiles } = this.state;

    if (!player1 || !player2) return null;

    const currentPlayer = this.determineCurrentPlayer();
    const winningTiles = getWinningTiles(tiles);
    const isFinished =
      !!winningTiles.length || tiles.filter(Boolean).length === tiles.length;
    const winner = winningTiles.length ? tiles[winningTiles[0]] : null;

    return (
      <>
        <h1 className={styles.title}>
          {winner
            ? `And the winner is: ${winner}!`
            : isFinished
            ? "Game over!"
            : `Your turn: ${currentPlayer}`}
        </h1>
        <Board>
          {tiles.map((tile, position) => (
            <Tile
              key={`tile-${position}`}
              value={tile}
              highlight={winningTiles.includes(position)}
              disabled={isFinished}
              onClick={() => this.onTileClick(position, currentPlayer)}
            />
          ))}
        </Board>
        {isFinished ? (
          <button className={styles.resetButton} onClick={this.resetGame}>
            Reset
          </button>
        ) : null}
      </>
    );
  }
}
